<template>
	<div class="project-status-dashboard card-shadow">
		<v-layout class="px-4 py-2 border-bottom">
			<div class="my-auto font-level-3-bold fw-600">Project Status</div>
			<v-spacer></v-spacer>
			<v-select
				class="cm-menu-select elevation-0 red--text"
				v-model="project_status"
				:items="projectStatusList"
				hide-details
				dense
				solo
			>
				<template v-slot:selection="{ item }">
					<span class="blue--text font-level-3-bold">{{ item }}</span>
				</template>
			</v-select>
		</v-layout>
		<div class="mx-auto px-4 py-3" style="max-width: 400px">
			<div>
				<apexchart
					type="donut"
					:options="chartOptions"
					:series="series"
					width="280px"
					class="chart-center"
				></apexchart>
			</div>
			<div class="total-project">
				<div class="total_no">235</div>
				<div class="fw-600">
					<span class="text-muted">Total Projects</span>
					<div color="green--text">
						<v-icon color="green" size="18px">mdi-arrow-top-right</v-icon>
						<span class="green--text"> + 3 New</span>
					</div>
				</div>
			</div>
			<div class="project-desc">
				<ul class="pa-0 ul">
					<li class="d-flex li" v-for="(row, index) in chartData" :key="index">
						<div class="d-flex">
							<div :class="`status_color mr-3 ${row.status_color}`"></div>
							<div class="name text-truncate">{{ row.status }}</div>
						</div>
						<div class="project-number text-truncate">{{ row.project_no }} <span>Projects</span></div>
						<div :class="`project-time text-truncate ${row.status_color}--text`">
							{{ row.project_time }} Hrs
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "ProjectStatus",
	data() {
		return {
			project_status: "All Time",
			projectStatusList: ["All Time", "This Week", "Last Week", "This Month", "Last Month"],
			series: [44, 55, 77, 15],
			chartOptions: {
				chart: {
					width: 280,
					type: "donut",
				},
				legend: {
					position: "bottom",
					show: false,
				},
			},
			chartData: [
				{
					status_color: "red",
					status: "Completed",
					project_no: 12,
					project_time: "15",
				},
				{
					status_color: "blue",
					status: "inProgress",
					project_no: 32,
					project_time: "45 ",
				},
				{
					status_color: "orange",
					status: "Yet to start",
					project_no: 50,
					project_time: "~135 ",
				},
				{
					status_color: "red",
					status: "Cancelled   ",
					project_no: 89,
					project_time: "~90",
				},
			],
		};
	},
};
</script>

<template>
	<div class="project-overview-dashboard card-shadow">
		<v-layout class="px-4 py-2 border-bottom">
			<div class="my-auto font-level-3-bold fw-600">Project Overview</div>
			<v-spacer></v-spacer>
			<v-select
				class="cm-menu-select elevation-0 red--text"
				v-model="project_overview"
				:items="projectFilterList"
				hide-details
				dense
				solo
			>
				<template v-slot:selection="{ item }">
					<span class="blue--text font-level-3-bold">{{ item }}</span>
				</template>
			</v-select>
		</v-layout>
		<v-layout>
			<v-flex class="border py-5 text-center">
				<div class="fs-24 fw-600">980</div>
				<div class="grey--text">Number of Projects</div>
			</v-flex>
			<v-flex class="border py-5 text-center">
				<div class="fs-24 fw-600">11,280</div>
				<div class="grey--text">Active Project</div>
			</v-flex>
			<v-flex class="border py-5 text-center">
				<div class="fs-24 fw-600">$1680 k</div>
				<div class="grey--text">Revenue</div>
			</v-flex>
			<v-flex class="border py-5 text-center">
				<div class="fs-24 fw-600 green--text">10,980hrs</div>
				<div class="grey--text">Working Hours</div>
			</v-flex>
		</v-layout>
		<div class="mx-auto px-4 py-3">
			<div>
				<apexchart
					type="line"
					height="450"
					:options="chartOptions"
					:series="series"
					width="100%"
				></apexchart>
			</div>
			<div class="project-desc">
				<ul class="pa-0 ul">
					<li class="d-flex li" v-for="(row, index) in chartData" :key="index">
						<div class="d-flex">
							<div :class="`status_color mr-3 ${row.status_color}`"></div>
							<div class="name text-truncate">{{ row.status }}</div>
						</div>
						<div class="project-number text-right">{{ row.order_no }}</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "ProjectStatus",
	data() {
		return {
			project_overview: "All Time",
			projectFilterList: ["All Time", "This Week", "Last Week", "This Month", "Last Month"],
			series: [
				{
					name: "Number of Projects",
					type: "column",
					data: [23, 11, 42, 27, 43, 22, 37, 21, 44, 22, 30, 12],
				},
				{
					name: "Revenue",
					type: "area",
					data: [44, 55, 41, 67, 62, 43, 41, 71, 56, 47, 43, 33],
				},
				{
					name: "Active Project",
					type: "column",
					data: [30, 25, 36, 30, 23, 35, 34, 52, 29, 26, 29, 44],
				},
			],
			chartData: [],
			chartOptions: {
				chart: {
					height: 450,
					type: "line",
					stacked: false,
				},
				stroke: {
					width: [0, 2, 5],
					curve: "smooth",
				},
				plotOptions: {
					bar: {
						columnWidth: "30%",
					},
				},

				fill: {
					opacity: [0.85, 0.25, 1],
					gradient: {
						inverseColors: false,
						shade: "light",
						type: "vertical",
						opacityFrom: 0.85,
						opacityTo: 0.55,
						stops: [0, 100, 100, 100],
					},
				},
				// labels: [
				// 	"Jan",
				// 	"Feb",
				// 	"Mar",
				// 	"Apr",
				// 	"May",
				// 	"Jun",
				// 	"Jul",
				// 	"Aug",
				// 	"Sep",
				// 	"Oct",
				// 	"Nov",
				// 	"Dec",
				// ],
				labels: [
					"01/01/2003",
					"02/01/2003",
					"03/01/2003",
					"04/01/2003",
					"05/01/2003",
					"06/01/2003",
					"07/01/2003",
					"08/01/2003",
					"09/01/2003",
					"10/01/2003",
					"11/01/2003",
				],
				markers: {
					size: 0,
				},
				xaxis: {
					type: "datetime",
				},
				yaxis: {
					title: {
						text: "Points",
					},
					min: 0,
				},
				tooltip: {
					shared: true,
					intersect: false,
					y: {
						formatter: function (y) {
							if (typeof y !== "undefined") {
								return y.toFixed(0) + " points";
							}
							return y;
						},
					},
				},
			},
		};
	},
};
</script>

<template>
	<div class="dashboard-main">
		<v-row>
			<v-col cols="12" class="px-0">
				<Viewdetails></Viewdetails>
			</v-col>
			<v-col cols="6" md="4">
				<OverAllStats></OverAllStats>
			</v-col>
			<v-col cols="6" md="4">
				<div id="chart"></div>
			</v-col>
			<v-col cols="6" md="4">
				<CalenderEvent></CalenderEvent>
			</v-col>
			<v-col cols="12" md="6" lg="4">
				<ProjectStatus></ProjectStatus>
			</v-col>
			<v-col cols="12" md="6" lg="4">
				<MyActivity></MyActivity>
			</v-col>
			<v-col cols="12" md="6" lg="4">
				<TotalUsers></TotalUsers>
			</v-col>
			<v-col cols="12" md="6  ">
				<TotalRevenue></TotalRevenue>
			</v-col>
			<v-col cols="12" md="6  ">
				<OrderState></OrderState>
			</v-col>
			<v-col cols="12" md="12">
				<ProjectOverview></ProjectOverview>
			</v-col>
			<v-col cols="12" md="12">
				<SalesAnalytics></SalesAnalytics>
			</v-col>
			<v-col cols="12" md="6">
				<Revenue></Revenue>
			</v-col>
			<v-col cols="12" md="6">
				<Revenue2></Revenue2>
			</v-col>
			<v-col cols="12" md="6">
				<DealStatus></DealStatus>
			</v-col>
			<v-col cols="12" md="6">
				<TeamMember></TeamMember>
			</v-col>
			<v-col cols="12" md="12">
				<Permission></Permission>
			</v-col>
		</v-row>
	</div>
</template>
<script>
import ProjectStatus from "@/view/pages/dashboard-ui/components/ProjectStatus";
import TotalUsers from "@/view/pages/dashboard-ui/components/TotalUsers";
import TotalRevenue from "@/view/pages/dashboard-ui/components/TotalRevenue";
import OrderState from "@/view/pages/dashboard-ui/components/OrderState";
import ProjectOverview from "@/view/pages/dashboard-ui/components/ProjectOverview";
import SalesAnalytics from "@/view/pages/dashboard-ui/components/SalesAnalytics";
import Revenue from "@/view/pages/dashboard-ui/components/Revenue";
import Revenue2 from "@/view/pages/dashboard-ui/components/Revenue2";
import DealStatus from "@/view/pages/dashboard-ui/components/DealStatus";
import Viewdetails from "@/view/pages/dashboard-ui/components/Viewdetails";
import TeamMember from "@/view/pages/dashboard-ui/components/TeamMember.vue";
import Permission from "@/view/module/permission/Permission";
import MyActivity from "@/view/pages/dashboard-ui/components/MyActivity";
import OverAllStats from "@/view/pages/dashboard-ui/components/OverAllStats";
import CalenderEvent from "@/view/pages/dashboard-ui/components/CalenderEvent.vue";
export default {
	name: "Dashboard",
	data() {
		return {};
	},
	components: {
		ProjectStatus,
		TotalUsers,
		TotalRevenue,
		OrderState,
		ProjectOverview,
		SalesAnalytics,
		Revenue,
		Revenue2,
		DealStatus,
		Viewdetails,
		TeamMember,
		Permission,
		MyActivity,
		OverAllStats,
		CalenderEvent,
	},
};
</script>

<template>
	<div class="total-revenue-dashboard card-shadow">
		<v-layout class="px-4 py-2 border-bottom">
			<div class="my-auto font-level-3-bold fw-600">Total Revenue</div>
			<v-spacer></v-spacer>
		</v-layout>
		<div class="mx-auto px-4 py-3 text-center" style="max-width: 400px">
			<div class="my-5">
				<v-progress-circular
					:rotate="360"
					:size="150"
					:width="15"
					value="65"
					color="orange"
					class="text-center"
				>
					<span class="fs-18 grey--text">
						65 %
						<br />
						Revenue
					</span>
				</v-progress-circular>
			</div>
			<div class="fw-500 grey--text fs-18">
				Total sales made Today
				<div class="fw-600 fs-24 blue--text">$ 178</div>
			</div>
			<div class="py-3 fs-18">
				<span
					>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni exercitationem fugit atque
					provident sed iure error.</span
				>
			</div>
			<ul class="ul statics d-flex pa-0 mt-5 py-5">
				<li class="li text-center">
					<div class="color747474">Target</div>
					<div class="mt-1">
						<v-icon size="28px" color="red">mdi-arrow-down</v-icon>
						<span class="number red--text">25 K</span>
					</div>
				</li>
				<li class="li text-center">
					<div class="color747474">Last Week</div>
					<div class="mt-1">
						<v-icon size="28px" color="green">mdi-arrow-up</v-icon>
						<span class="number green--text">25 K</span>
					</div>
				</li>
				<li class="li text-center">
					<div class="color747474">Last Month</div>
					<div class="mt-1">
						<v-icon size="28px" color="blue">mdi-arrow-up </v-icon>
						<span class="number blue--text">25 K</span>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
export default {
	name: "TotalUsers",
	data() {
		return {};
	},
};
</script>

<template>
	<div class="card-shadow" style="max-width: 100%">
		<v-layout class="px-4 py-2 border-bottom">
			<div class="my-auto font-level-3-bold fw-600">Calendar Events</div>
			<v-spacer></v-spacer>
		</v-layout>
		<v-layout>
			<div class="pa-3">
				<v-date-picker
					v-model="date2"
					:event-color="(date) => (date[9] % 2 ? 'red' : 'green')"
					:events="functionEvents"
					prev-icon="mdi-skip-previous"
					next-icon="mdi-skip-next"
					color="blue darken-4"
					class="border"
					:picker-date.sync="currentMonth"
				></v-date-picker>
			</div>
			<v-flex class="pa-3">
				<div class="my-auto fw-600 h2 pb-1 border-bottom">November {{ currentMonth }}</div>
				<div class="" style="height: 320px; overflow: auto">
					<div class="d-flex border-bottom py-2" v-for="i in 10" :key="i">
						<div class="pa-2">
							<v-icon color="grey lighten-1" size="30">mdi-calendar</v-icon>
						</div>
						<div class="">
							<p class="mb-1 fw-600">Event name here...</p>
							<p class="mb-0 text-muted">Due by <span class="primary--text">Chris Brown</span></p>
						</div>
					</div>
				</div>
			</v-flex>
		</v-layout>
	</div>
</template>
<script>
export default {
	name: "CalendarEvents",
	data() {
		return {
			currentMonth: null,
			arrayEvents: null,
			date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
		};
	},
	watch: {
		pickerDate(newval) {
			return newval;
		},
	},
	methods: {
		functionEvents(date) {
			const [, , day] = date.split("-");
			if ([12, 17, 28].includes(parseInt(day, 10))) return true;
			if ([1, 19, 22].includes(parseInt(day, 10))) return ["red", "#00f"];
			return false;
		},
	},
	mounted() {
		this.arrayEvents = [...Array(6)].map(() => {
			const day = Math.floor(Math.random() * 30);
			const d = new Date();
			d.setDate(day);
			return d.toISOString().substr(0, 10);
		});
	},
};
</script>

<template>
	<div class="team-preview">
		<div class="team-wrapper card-shadow">
			<div class="team-fields pb-2">
				<div class="team-grp">
					<v-layout class="px-4 py-2 border-bottom">
						<div class="my-auto font-level-3-bold fw-600">Team Members</div>
						<v-spacer></v-spacer>
						<div class="d-flex">
							<v-select
								class="cm-menu-select elevation-0 red--text"
								v-model="status"
								:items="StatusList"
								hide-details
								dense
								solo
							>
								<template v-slot:selection="{ item }">
									<span class="blue--text font-level-3-bold">{{ item }}</span>
								</template>
							</v-select>
						</div>
					</v-layout>
					<div class="v-data-table bt-table theme--light">
						<div class="v-data-table__wrapper">
							<table id="table" width="100">
								<thead>
									<tr>
										<th>Member</th>
										<th>Hours</th>
										<th>Tasks</th>
										<th style="text-align: center">Status</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<div class="d-flex align-items-center">
												<v-avatar rounded size="40">
													<img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John"
												/></v-avatar>
												<div class="user_profile">
													<h4>Donald Risher</h4>
													<p class="mb-0">Product Manager</p>
												</div>
											</div>
										</td>
										<td>
											<p class="fw-500 mb-0">110h :<span>150h</span></p>
										</td>
										<td><p class="fw-500 mb-0">250</p></td>
										<td style="text-align: right">
											<span class="indigo--text fw-500">50%</span>
											<v-progress-circular :value="50" color="indigo darken-2"></v-progress-circular>
										</td>
									</tr>
									<tr>
										<td>
											<div class="d-flex align-items-center">
												<v-avatar rounded size="40">
													<img src="https://cdn.vuetifyjs.com/images/john.png" alt="John"
												/></v-avatar>
												<div class="user_profile">
													<h4>Jansh Brown</h4>
													<p class="mb-0">Lead Developer</p>
												</div>
											</div>
										</td>
										<td>
											<p class="fw-500 mb-0">83h :<span>150h</span></p>
										</td>
										<td><p class="fw-500 mb-0">105</p></td>
										<td style="text-align: right">
											<span class="indigo--text fw-500">50%</span>
											<v-progress-circular :value="50" color="indigo darken-2"></v-progress-circular>
										</td>
									</tr>
									<tr>
										<td>
											<div class="d-flex align-items-center">
												<v-avatar rounded size="40">
													<img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John"
												/></v-avatar>
												<div class="user_profile">
													<h4>Donald Risher</h4>
													<p class="mb-0">Lead Designer</p>
												</div>
											</div>
										</td>
										<td>
											<p class="fw-500 mb-0">58h : <span>150h</span></p>
										</td>
										<td><p class="fw-500 mb-0">75</p></td>
										<td style="text-align: right">
											<span class="indigo--text fw-500">80%</span>
											<v-progress-circular :value="80" color="indigo darken-2"></v-progress-circular>
										</td>
									</tr>
									<tr>
										<td>
											<div class="d-flex align-items-center">
												<v-avatar rounded size="40">
													<img src="https://cdn.vuetifyjs.com/images/john.png" alt="John"
												/></v-avatar>
												<div class="user_profile">
													<h4>William Pinto</h4>
													<p class="mb-0">UI/Ux Designer</p>
												</div>
											</div>
										</td>
										<td>
											<p class="fw-500 mb-0">96h : <span>150h</span></p>
										</td>
										<td><p class="fw-500 mb-0">85</p></td>
										<td style="text-align: right">
											<span class="lime--text fw-500">30%</span>
											<v-progress-circular :value="30" color="lime"></v-progress-circular>
										</td>
									</tr>
									<tr>
										<td>
											<div class="d-flex align-items-center">
												<v-avatar rounded size="40">
													<img src="https://cdn.vuetifyjs.com/images/lists/2.jpg" alt="John"
												/></v-avatar>
												<div class="user_profile">
													<h4>Donald Risher</h4>
													<p class="mb-0">Product Manager</p>
												</div>
											</div>
										</td>
										<td>
											<p class="fw-500 mb-0">76h : <span>150h</span></p>
										</td>
										<td><p class="fw-500 mb-0">69</p></td>
										<td style="text-align: right">
											<span class="indigo--text fw-500">70%</span>
											<v-progress-circular :value="70" color="indigo darken-2"></v-progress-circular>
										</td>
									</tr>
									<tr>
										<td>
											<div class="d-flex align-items-center">
												<v-avatar rounded size="40">
													<img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John"
												/></v-avatar>
												<div class="user_profile">
													<h4>Carroll Adams</h4>
													<p class="mb-0">Product Manager</p>
												</div>
											</div>
										</td>
										<td>
											<p class="fw-500 mb-0">123h : <span>150h</span></p>
										</td>
										<td><p class="fw-500 mb-0">658</p></td>
										<td style="text-align: right">
											<span class="cyan--text fw-500">85%</span>
											<v-progress-circular :value="85" color="cyan"></v-progress-circular>
										</td>
									</tr>
									<tr>
										<td>
											<div class="d-flex align-items-center">
												<v-avatar rounded size="40">
													<img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John"
												/></v-avatar>
												<div class="user_profile">
													<h4>Carroll Adams</h4>
													<p class="mb-0">Product Manager</p>
												</div>
											</div>
										</td>
										<td>
											<p class="fw-500 mb-0">123h : <span>150h</span></p>
										</td>
										<td><p class="fw-500 mb-0">658</p></td>
										<td style="text-align: right">
											<span class="cyan--text fw-500">85%</span>
											<v-progress-circular :value="85" color="cyan"></v-progress-circular>
										</td>
									</tr>
									<tr>
										<td>
											<div class="d-flex align-items-center">
												<v-avatar rounded size="40">
													<img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John"
												/></v-avatar>
												<div class="user_profile">
													<h4>Carroll Adams</h4>
													<p class="mb-0">Product Manager</p>
												</div>
											</div>
										</td>
										<td>
											<p class="fw-500 mb-0">123h : <span>150h</span></p>
										</td>
										<td><p class="fw-500 mb-0">658</p></td>
										<td style="text-align: right">
											<span class="cyan--text fw-500">85%</span>
											<v-progress-circular :value="85" color="cyan"></v-progress-circular>
										</td>
									</tr>
									<tr>
										<td>
											<div class="d-flex align-items-center">
												<v-avatar rounded size="40">
													<img src="https://cdn.vuetifyjs.com/images/lists/2.jpg" alt="John"
												/></v-avatar>
												<div class="user_profile">
													<h4>Joseph Jackson</h4>
													<p class="mb-0">React Developer</p>
												</div>
											</div>
										</td>
										<td>
											<p class="fw-500 mb-0">117h : <span>150h</span></p>
										</td>
										<td><p class="fw-500 mb-0">125</p></td>
										<td style="text-align: right">
											<span class="indigo--text fw-500">70%</span>
											<v-progress-circular :value="70" color="indigo darken-2"></v-progress-circular>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "team-member",
	data() {
		return {
			status: "All Time",
			StatusList: ["All Time", "This Week", "Last Week", "This Month", "Last Month"],
		};
	},
};
</script>
<style scoped>
.v-progress-circular {
	margin: 1rem;
}
</style>

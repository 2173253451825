<template>
	<div class="card-shadow">
		<div class="pb-2">
			<v-layout class="px-4 py-2 border-bottom">
				<div class="my-auto font-level-3-bold fw-600">Permissions</div>
				<v-spacer></v-spacer>
			</v-layout>
			<div class="v-data-table bt-table theme--light">
				<div class="v-data-table__wrapper">
					<table width="100">
						<thead>
							<tr>
								<th width="200"></th>
								<th width="100">Full Access</th>
								<th width="100">View</th>
								<th width="100">Create</th>
								<th width="100">Edit</th>
								<th width="100">Delete</th>
								<th width="100">Approve</th>
							</tr>
						</thead>
						<tbody>
							<template v-for="(row, index) in permission">
								<tr :key="index" :class="row.menu_open ? 'grey lighten-3' : ''">
									<td>
										<template v-if="row.has_menu">
											<v-icon v-on:click="toggleMenu(index)" class="mr-1 link">
												<template v-if="row.menu_open">mdi-minus</template>
												<template v-else>mdi-plus</template>
											</v-icon>
											<span class="fw-500 mb-0">{{ row.name }}</span>
										</template>
										<template v-else>
											<span class="fw-500 mb-0 pl-6">{{ row.name }}</span>
										</template>
									</td>
									<td>
										<v-checkbox hide-details class="ma-0 pa-0"></v-checkbox>
									</td>
									<td>
										<v-checkbox hide-details class="ma-0 pa-0"></v-checkbox>
									</td>
									<td>
										<v-checkbox hide-details class="ma-0 pa-0"></v-checkbox>
									</td>
									<td>
										<v-checkbox hide-details class="ma-0 pa-0"></v-checkbox>
									</td>
									<td>
										<v-checkbox hide-details class="ma-0 pa-0"></v-checkbox>
									</td>
									<td>
										<v-checkbox hide-details class="ma-0 pa-0"></v-checkbox>
									</td>
								</tr>
								<template v-if="row.menu_open && row.submenu_list.length">
									<tr v-for="(child, cindx) in row.submenu_list" :key="`${index}-${cindx}`">
										<td>
											<div class="pl-12">
												<span class="fw-500 mb-0">{{ child.name }}</span>
											</div>
										</td>
										<td>
											<v-checkbox hide-details class="ma-0 pa-0"></v-checkbox>
										</td>
										<td>
											<v-checkbox hide-details class="ma-0 pa-0"></v-checkbox>
										</td>
										<td>
											<v-checkbox hide-details class="ma-0 pa-0"></v-checkbox>
										</td>
										<td>
											<v-checkbox hide-details class="ma-0 pa-0"></v-checkbox>
										</td>
										<td>
											<v-checkbox hide-details class="ma-0 pa-0"></v-checkbox>
										</td>
										<td>
											<v-checkbox hide-details class="ma-0 pa-0"></v-checkbox>
										</td>
									</tr>
								</template>
							</template>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "permission",
	title: "Permission",
	data() {
		return {
			permission: [
				{
					name: "Dashboard",
					menu_open: false,
					has_menu: false,
				},
				{
					name: "Leads",
					menu_open: false,
					has_menu: true,
					submenu_list: [
						{
							name: "Activity",
						},
						{
							name: "Notes",
						},
						{
							name: "Task",
						},
						{
							name: "Meeting",
						},
						{
							name: "Reminder",
						},
					],
				},
				{
					name: "Customer",
					menu_open: false,
					has_menu: true,
					submenu_list: [
						{
							name: "Activity",
						},
						{
							name: "Notes",
						},
						{
							name: "Task",
						},
						{
							name: "Meeting",
						},
						{
							name: "Reminder",
						},
					],
				},
				{
					name: "Meeting",
					menu_open: false,
					has_menu: true,
					submenu_list: [
						{
							name: "Activity",
						},
						{
							name: "Notes",
						},
						{
							name: "Reminder",
						},
					],
				},
				{
					name: "Leave",
					menu_open: false,
					has_menu: false,
				},
				{
					name: "Holiday",
				},
				{
					name: "Task",
					menu_open: false,
					has_menu: true,
					submenu_list: [
						{
							name: "Activity",
						},
						{
							name: "Notes",
						},
						{
							name: "Checklist",
						},
						{
							name: "Meeting",
						},
						{
							name: "Reminder",
						},
					],
				},
				{
					name: "Claims",
					menu_open: false,
					has_menu: true,
					submenu_list: [
						{
							name: "Activity",
						},
						{
							name: "Task",
						},

						{
							name: "Reminder",
						},
					],
				},
				{
					name: "Activity",
				},
			],
		};
	},
	methods: {
		toggleMenu(index) {
			this.permission[index].menu_open = !this.permission[index].menu_open;
		},
	},
};
</script>
